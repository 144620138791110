<div class="cb-checkout-ready">
	
	@if(this.paymentInbound) {
		<div class="cb-checkout-ready-payment-inbound">
			<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
		</div>
	}

	<div class="cb-checkout-ready-summary-cart" #powSummaryCart>
		<app-summary-cart></app-summary-cart>
	</div>

	<div class="cb-checkout-ready-content">
		<h2>Paiement</h2>

		<div class="cb-checkout-ready-address">
			<div class="cb-checkout-title">
				<h3>Adresse de livraison</h3>
			</div>

			<div class="cb-checkout-ready-address-info">
				@if (this.deliveredAddress) {
					<p>{{ this.deliveredAddress.firstName }} {{ this.deliveredAddress.lastName }}</p>
					<p>{{ this.deliveredAddress.street }}</p>
					<p>{{ this.deliveredAddress.zip }} {{ this.deliveredAddress.city }} {{ this.deliveredAddress.country }}</p>

					@if (this.isShopAddress(this.deliveredAddress)) {
						<p>7-13h 16-19h</p>
					}
				}
			</div>

			<a [routerLink]="[this.sitemap.checkout.route]">
				<strong>Modifier</strong>
			</a>
		</div>

		<div class="cb-checkout-promo-code-content">
			<h3 class="cb-checkout-title">Code promo</h3>

			<form class="cb-form" [formGroup]="this.codeForm" (submit)="this.promoCodeSubmit()">
				<mat-form-field class="cb-form-label" appearance="outline">
					<mat-label>Code</mat-label>
					<input
						class="cb-form-input"
						matInput
						#promoCode
						type="text"
						placeholder="XXXXXXXX"
						formControlName="promoCode"
						required
					/>
					<mat-icon matSuffix>percent</mat-icon>
					<mat-error>Code promo requis</mat-error>
				</mat-form-field>

				@if (!this.promoCodeSuccess) {
					<app-cb-button
						class="cb-form-button"
						color="primary"
						text="appliquer"
						type="submit"
						[matTooltip]="this.codeForm.invalid ? 'Aucun code renseigné' : ''"
						[matTooltipPositionAtOrigin]="true"
						[disabled]="this.codeForm.invalid || this.promoCodeSubmitted || this.promoCodeSuccess || this.codeForm.disabled"
						[loading]="this.promoCodeSubmitted"
					></app-cb-button>
				}

				@else {
					<app-cb-button
						class="cb-form-button"
						color="primary"
						text="retirer"
						type="button"
						(click)="this.resetPromoCode()"
					></app-cb-button>
				}

				@if (this.promoCodeFinished) {
					@if (this.promoCodeSuccess) {
						<mat-icon class="cb-form-icon success">check</mat-icon>
					}

					@else {
						<mat-icon class="cb-form-icon failure">close</mat-icon>
					}
				}
			</form>

			<mat-checkbox [checked]="this.promoCode.value ? true : false" (click)="this.togglePromoCodeForm()">
				<p class="pow-choices-info">Utiliser un code promo</p>
			</mat-checkbox>
		</div>

		<div class="cb-checkout-ready-payment">
			<div class="cb-checkout-title">
				<h3>Informations de paiement</h3>
			</div>

			<app-cb-payment-form
				class="cb-checkout-ready-payment-form"
				[payment]="this.paymentInfo"
				(invalid)="this.invalidPaymentForm = $event"
				(paymentEvent)="this.paymentInfo = $event"
			>
			</app-cb-payment-form>

			<!-- [TODO]: Feature d'enregistrement des coordonnées de paiement de l'utilisateur -->
			<!-- <mat-checkbox>
				<p class="pow-choices-info">Enregistrer les informations de paiement pour les futurs achats</p>
			</mat-checkbox> -->
		</div>

		<div class="cb-checkout-ready-facture">
			<div class="cb-checkout-title">
				<h3>Adresse de facturation</h3>
			</div>

			<app-cb-address-selector
				[addresses]="this.addresses"
				[address]="this.billedAddress"
				[options]="false"
				[disabled]="this.sameAddress"
				(addressEvent)="this.updateBilledAddress($event)"
			></app-cb-address-selector>

			<mat-checkbox
				(change)="this.updateBilledAddress(this.deliveredAddress)"
				[disabled]="this.isNotKnownAddress()"
				[(ngModel)]="this.sameAddress"
			>
				<p class="pow-choices-info">Adresse de facturation identique à l'adresse de livraison</p>
			</mat-checkbox>
		</div>

		<div class="cb-checkout-button">
			<app-cb-button
				color="primary"
				text="commander"
				[matTooltip]="!this.addresses.length ? 'Vous devez enregistrer au moins une adresse de facturation pour continuer' : ''"
				[matTooltipPositionAtOrigin]="true"
				[disabled]="this.invalidPaymentForm || !this.billedAddress || !this.deliveredAddress || this.submitted"
				[loading]="this.submitted"
				(click)="this.submit()"
			></app-cb-button>
		</div>
	</div>
</div>