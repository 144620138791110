import { Pipe, PipeTransform } from '@angular/core';

import { Address } from '../../models/address';

@Pipe({
	name: 'toAddress',
	standalone: true
})
export class ToAddressPipe implements PipeTransform {

	public transform = (value: Address | string): Address => (typeof value === 'string') ? JSON.parse(value) : value;

}
