import { Component, Inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material/table";
import saveAs from "file-saver";
import * as XLSX from "xlsx";

import { OrderedProduct } from "../../../_commons/models/ordered-product";
import { Order } from "../../../_commons/models/order";

import { ToPricePipe } from "../../../_commons/pipes/to-price/to-price.pipe";

import { PDFDocument, PageSizes } from "pdf-lib";

@Component({
	selector: "app-admin-orders-modal",
	standalone: true,
	imports: [MatButtonModule, MatDialogModule, MatTableModule, MatIconModule, ToPricePipe],
	templateUrl: "./admin-orders-modal.component.html",
	styleUrl: "./admin-orders-modal.component.scss",
})
export class AdminOrdersModalComponent {
	public dataSource!: OrderedProduct[];
	public displayedColumns: string[] = [];

	constructor(@Inject(MAT_DIALOG_DATA) private data: Order) {
		this.initializeDisplayedColumns();
	}

	public ngOnInit(): void {
		this.dataSource = this.data.cart.orderedProducts;
	}

	private initializeDisplayedColumns(): void {
		this.displayedColumns = ["id", "produit", "prix", "quantité", "total", "facturedAddress"];
	}

	private generateXLSX(): Blob {
		const formattedData = this.data.cart.orderedProducts.map((item) => ({
			id: item.id,
			quantity: item.quantity,
			product: item.product.name,
			price: item.price.value,
			amount: item.quantity * item.price.value,
		}));

		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formattedData);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "OrderedProducts");

		// Create a blob in XLSX format
		const wbout: Blob = new Blob([XLSX.write(wb, { bookType: "xlsx", type: "array" })], {
			type: "application/octet-stream",
		});

		return wbout;
	}

	public exportToExcel(): void {
		const wbout = this.generateXLSX();
		saveAs(wbout, `CARREMENT_BON_${this.data.id}.xlsx`);
	}

	public async exportToPDF(): Promise<void> {
		const pdfDoc = await PDFDocument.create();

		const page = pdfDoc.addPage([PageSizes.A4[1], PageSizes.A4[0]]);

		const { width, height } = page.getSize();
		const fontSize = 10;
		let offsetY = height - 40;

		const columnPositions = [30, 60, 310, 440, 570, 700];
		const lineHeight = 20;

		this.displayedColumns.forEach((header, index) => {
			page.drawText(header, { x: columnPositions[index], y: offsetY, size: fontSize });
		});

		this.dataSource.forEach((item) => {
			offsetY -= lineHeight;
			const idText = item.id ? item.id.toString() : "N/A";
			page.drawText(idText, { x: columnPositions[0], y: offsetY, size: fontSize });
			page.drawText(item.product.name, { x: columnPositions[1], y: offsetY, size: fontSize });
			page.drawText(`${item.price.value} €`, { x: columnPositions[2], y: offsetY, size: fontSize });
			page.drawText(item.quantity.toString(), {
				x: columnPositions[3],
				y: offsetY,
				size: fontSize,
			});
			page.drawText((item.quantity * item.price.value).toFixed(2) + " €", {
				x: columnPositions[4],
				y: offsetY,
				size: fontSize,
			});
			//page.drawText(item.facturedAddress, { x: columnPositions[5], y: offsetY, size: fontSize });
		});

		const pdfBytes = await pdfDoc.save();
		this.download(pdfBytes, `Commandes_${this.data.id}.pdf`, "application/pdf");
	}

	private download(data: Uint8Array, filename: string, type: string) {
		const blob = new Blob([data], { type: type });
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = filename;
		link.click();
		URL.revokeObjectURL(link.href);
	}
}
